import gsap from 'gsap'

const init = (el) => {
  gsap.set(el.parentNode, {
    // perspective: 2000,
  });

  const params = {
    autoAlpha: 0,
    y: 40,
    skewX: 15,
    rotateY: 20,
    transformOrigin: '0% 0%',
  }

  return gsap.set(el, Object.assign({}, params))
}

const anim = (el, extraParams) => {
  const params = {
    autoAlpha: 1,
    y: 0,
    skewX: 0,
    rotateY: 0,
    clearProps: 'all',
    ease: 'expo.out',
    duration: 1.8,
  }
  return gsap.to(el, Object.assign({}, params, extraParams))
}

export {
  init,
  anim
}
