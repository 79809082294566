<template>
  <header>
    <div class="wrapper">
      <div class="flex ai-c jc-e">
        <div class="cta flex jc-e" data-anim="fade">
          <div class="btn flex ai-c jc-c" @click="open">
            <img :src="require('@/assets/svg/basket.svg')" />
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import scroll from '@/assets/js/scroll'

export default {
  name: 'Header',
  props: {
    open: {
      type: Function,
      default: () => {}
    }
  }
}
</script>

<style lang="scss" scoped>
header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  pointer-events: none;

  .cta {
    .btn {
      position: relative;
      cursor: pointer;
      width: 48px;
      height: 48px;
      pointer-events: auto;
      
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background: var(--color-primary);
        @include mq(sm) {
          transform: scale(1);
          transition: transform 0.5s var(--ease);
        }
      }

      @include mq(sm) {
        width: 72px;
        height: 72px;
      }

      img {
        position: relative;
        z-index: 2;
        width: 16px;

        @include mq(sm) {
          transform: scale(0.9);
          transition: transform 0.5s var(--ease);
          width: 20px;
        }
      }

      @include mq(sm) {
        &:hover {
          &::before {
            transform: scale(0.83);
          }
  
          img {
            transform: scale(1);
          }
        }
      }
    }
  }
}
</style>