import Vue from 'vue';
import Prismic from 'prismic-javascript';
import prismic from '@/assets/js/prismic';
import gsap from 'gsap';
import Vuelidate from 'vuelidate'
import App from './App.vue';

Vue.use(Vuelidate, {
  behavior: 'eager'
})

Vue.config.productionTip = false;
const path = window.location.pathname.split('/');
const lang = path.includes('en') ? 'en-gb' : path.includes('de') ? 'de-de' : 'it-it';
window.lang = lang;
let paypal_lang = 'it_IT';

if (lang === 'en-gb') {
  document.title = 'RucolaPiù — We started from our rucola and we just couldn’t stop.'
  paypal_lang = 'en_GB';
} else if (lang === 'de-de') {
  document.title = 'RucolaPiù — Wir haben mit Rucola angefangen und sind auf den Geschmack gekommen.'
  paypal_lang = 'de_DE';
}

document.body.classList.add(`lang-${lang}`)
const script = document.createElement('script');
script.async = true;
// const key = 'AQxD3QAEOoZzyihoWr2SD8lgxMsr0OQi8Fu_5bnAAH_uWuWBBJX_Q0J7zV0T5N5ULQwj4n1mOdwVO356';
const liveKey = 'AbANkwXhaTdnsd_bmtkM6S-5YKp_iHGpM_wDtJbx3Hu9_90PePpUwCRLqfDIdOl4lNWpYFadbfVNMGrB';

script.dataset.sdkIntegrationSource = 'button-factory';
script.src = `https://www.paypal.com/sdk/js?client-id=${liveKey}&currency=EUR&locale=${paypal_lang}&intent=capture`;
document.head.appendChild(script);

script.onload = () => {
  Prismic.getApi('https://rucolapiu.cdn.prismic.io/api/v2')
  .then((api) => api.getByUID('landing', 'index', {
    lang,
  })
    .then((document) => {
      window._DATA = {}
      document.data.body.map((section) => {
        window._DATA[section.slice_type] = section;
      })
      window._DATA = {
        ...window._DATA,
        footer: document.data.footer_column,
        labels: {
          follow_us: document.data.follow_us,
          info_paypal: document.data.info_paypal,
          payoff: document.data.payoff,
          product_1: document.data.product_1,
          product_2: document.data.product_2,
          product_3: document.data.product_3,
          btn_cart: document.data.btn_cart
        },
        thx: {
          title: document.data.thx_title,
          text: document.data.thx_text,
        },
        form: {
          firstname: document.data.firstname_label,
          lastname: document.data.lastname_label,
          email: document.data.email_label,
          phone: document.data.phone_label,
          cfiva: document.data.cfiva_label,
          address: document.data.address_label,
          postalcode: document.data.postalcode_label,
          city: document.data.city_label,
          state: document.data.state_label,
          country: document.data.country_label,
          countryForeign: document.data.country_foreign_value,
          otherCountry: document.data.othercountry_label,
          product: document.data.product_label,
          error: document.data.error_label,
        }
      }

      gsap.to('.loader', {
        autoAlpha: 0,
        pointerEvents: 'none',
        duration: 0.7,
        ease: 'expo.out',
      }).then(() => {
        new Vue({
          labels: window._DATA.labels,
          form: window._DATA.form,
          render: (h) => h(App),
        }).$mount('#app');
      })
    })
  );
};