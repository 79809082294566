<template>
  <footer>
    <div class="pattern" :style="{ backgroundImage: `url(${require('@/assets/svg/pattern.svg')})`}"></div>
    <div class="grid g-12" data-anim="fade">
      <div class="brand color--white">
        <img :src="require('@/assets/svg/logo-alt.svg')" />
        <div class="payoff">{{ $root.$options.labels.payoff }}</div>
      </div>

      <div v-for="(item, index) in data" :key="index" :class="['m-t-xxxs-lg', 'column', `column-${index}`]">
        <prismic-rich-text class="color--white" :field="item.title"/>
        <prismic-rich-text class="color--dark a--color--white m-t-xxxs-md text" :field="item.text"/>
      </div>

      <div class="social m-t-xxxs-lg">
        <h4 class="color--white">{{ $root.$options.labels.follow_us }}</h4>
        <ul class="flex m-t-xxxs-md">
          <li>
            <a href="https://www.instagram.com/rucolapiu/" target="_blank" class="default">
              <img src="/static/svg/ig.svg" alt="">
            </a>
          </li>
          <li>
            <a href="https://fb.me/rucolapiu" target="_blank" class="default">
              <img src="/static/svg/fb.svg" alt="">
            </a>
          </li>
          <li>
            <a href="https://rucolapiu.medium.com/" target="_blank" class="default">
              <img src="/static/svg/medium.svg" alt="">
            </a>
          </li>
        </ul>
      </div>
      <div class="extra m-t-xxxs-lg flex jc-sb ai-b">
        <div class="lang">
          <ul class="flex m-t-xxxs-md a--color--white">
            <li>
              <a href="/" :class="{ current : currentLang === 'it-it' }">
                IT
              </a>
            </li>
            <li>
              <a href="/en" :class="{ current : currentLang === 'en-gb' }">
                EN
              </a>
            </li>
            <li>
              <a href="/de" :class="{ current : currentLang === 'de-de' }">
                DE
              </a>
            </li>
          </ul>
        </div>
        <div class="a--color--white color--white">
          <ul class="flex">
            <li>
              <p><a :href="privacyUrl" target="_blank">Privacy Policy</a> & <a :href="cookieUrl" target="_blank">Cookie Policy</a></p>
            </li>
            <li>
              <p><a href="https://www.semplice.is/" target="_blank">Credits</a></p>
            </li>
          </ul>
        </div>
        <div class="copy m-t-xxxs-md m-t-xxxs-0">
          <a href="http://www.bioagricolass.it/" target="_blank" class="default">
            <img src="/static/svg/bioagricola.svg" alt="">
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  props: {
    data: {
      type: Array,
      default: () => []
    },
  },
  computed: {
    currentLang() {
      return window.lang
    },
    privacyUrl() {
      let url = 'https://www.iubenda.com/privacy-policy/36989867'

      if (window.lang === 'en-gb') url = 'https://www.iubenda.com/privacy-policy/16271135'
      if (window.lang === 'de-de') url = 'https://www.iubenda.com/privacy-policy/71676304'

      return url
    },
    cookieUrl() {
      let url = 'https://www.iubenda.com/privacy-policy/36989867/cookie-policy'
      if (window.lang === 'en-gb') url = 'https://www.iubenda.com/privacy-policy/16271135/cookie-policy'
      if (window.lang === 'de-de') url = 'https://www.iubenda.com/privacy-policy/71676304/cookie-policy'
      return url
    }
  }
}
</script>

<style lang="scss" scoped>
footer {
  position: relative;
  background: var(--color-primary);

  padding: var(--spacer-lg) var(--spacer-md) var(--spacer-md) var(--spacer-md);

  @include mq(sm) {
    padding: var(--spacer-xxl) var(--spacer-xl) var(--spacer-lg) var(--spacer-xl);
  }
}

::v-deep h4 {
  @extend %typo--xxs;
  text-transform: none;
}

.pattern {
  top: 8px;
  left: 0;
  position: absolute;
  width: 100%;
  height: 56px;
  background-repeat: repeat-x;
  background-size: auto 100%;
}

.grid {
  padding-top: 64px;
}

.brand {
  img {
    max-width: 216px;
    margin-bottom: var(--spacer-xxs);
  }
}
.current {
  pointer-events: none;
  color: var(--color-purple);

  &::after {
    transform: scaleX(1);
  }
}

.payoff {
  font-weight: var(--weight-demi);
}

.column {
  grid-column: 1 / -1;
  
  @include mq(sm) {
    grid-column: auto / span 3;
  }
}

.column-0 {
  @include mq(sm) {
    grid-column: 1 / 3;
  }
}

.column-1 {
  @include mq(sm) {
    grid-column: 4 / 7;
  }
}

.text {
  max-width: 80%;
}

.extra {
  grid-column: 1 / -1;
}

.brand,
.social {
  grid-column: 1 / -1;
  @include mq(sm) {
    grid-column: 1 / 4;
  }
}

.lang {
  grid-column: 1 / -1;
  line-height: 24px;
  margin-bottom: var(--spacer-xs);
  @include mq(sm) {
    margin-bottom: 0;
  }

  .flex li + li {
    margin-left: var(--spacer-xs);
    flex: 1 0 0;
  }
}

.social {
  li + li {
    margin-left: var(--spacer-xs);
  }
}

.extra {
  li + li {
    flex: 0 0 100%;
    @include mq(sm) {
      flex: 1 0 0;
      margin-left: var(--spacer-md);
    }
  }
}
</style>