import LocomotiveScroll from 'locomotive-scroll'

const $scroll = {
  ref: null,
  direction: null,
  init: () => {
    $scroll.ref = new LocomotiveScroll({
      el: document.querySelector('[data-scroll-container]'),
      smooth: true,
      lerp: 0.1,
      smartphone: {
        smooth: false
      }
    })
  }
}

export default $scroll