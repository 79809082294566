<template>
  <div class="modal">
    <section>
      <div class="close" @click="close">
        <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><path fill="white" d="M289.94 256l95-95A24 24 0 00351 127l-95 95-95-95a24 24 0 00-34 34l95 95-95 95a24 24 0 1034 34l95-95 95 95a24 24 0 0034-34z"/></svg>
      </div>
      <div class="form grid g-12">
        <div class="form-group" :class="{ 'form-group--error': $v.firstname.$error }">
          <input class="form__input" @focus="focus" @blur="(e) => blur(e, 'firstname')" v-model.trim="$v.firstname.$model" :placeholder="`${$root.$options.form.firstname} *`"/>
          <div class="error" v-if="$v.firstname.$dirty && !$v.firstname.required">{{ $root.$options.form.error }}</div>
        </div>
        <div class="form-group" :class="{ 'form-group--error': $v.lastname.$error }">
          <input class="form__input" @focus="focus" @blur="(e) => blur(e, 'lastname')" v-model.trim="$v.lastname.$model" :placeholder="`${$root.$options.form.lastname} *`"/>
          <div class="error" v-if="$v.lastname.$dirty && !$v.lastname.required">{{ $root.$options.form.error }}</div>
        </div>
        <div class="form-group" :class="{ 'form-group--error': $v.email.$error }">
          <input class="form__input" @focus="focus" @blur="(e) => blur(e, 'email')" v-model.trim="$v.email.$model" :placeholder="`${$root.$options.form.email} *`"/>
          <div class="error" v-if="$v.email.$dirty && !$v.email.required">{{ $root.$options.form.error }}</div>
        </div>
        <div class="form-group" :class="{ 'form-group--error': $v.phone.$error }">
          <input class="form__input" @focus="focus" @blur="(e) => blur(e, 'phone')" v-model.trim="$v.phone.$model" :placeholder="`${$root.$options.form.phone} ${country === 'IT' ? '*' : ''}`"/>
          <div class="error" v-if="$v.phone.$dirty && !$v.phone.required">{{ $root.$options.form.error }}</div>
        </div>
        <div class="form-group form-group--double" :class="{ 'form-group--error': $v.cfiva.$error }">
          <input class="form__input" @focus="focus" @blur="(e) => blur(e, 'cfiva')" v-model.trim="$v.cfiva.$model" :placeholder="`${$root.$options.form.cfiva} ${country === 'IT' ? '*' : ''}`"/>
          <div class="error" v-if="$v.cfiva.$dirty && !$v.cfiva.required">{{ $root.$options.form.error }}</div>
        </div>
        <div class="form-group form-group--double" :class="{ 'form-group--error': $v.address.$error }">
          <input class="form__input" @focus="focus" @blur="(e) => blur(e, 'address')" v-model.trim="$v.address.$model" :placeholder="`${$root.$options.form.address} *`"/>
          <div class="error" v-if="$v.address.$dirty && !$v.address.required">{{ $root.$options.form.error }}</div>
        </div>
        <div class="form-group" :class="{ 'form-group--error': $v.postalcode.$error }">
          <input class="form__input" @focus="focus" @blur="(e) => blur(e, 'postalcode')" v-model.trim="$v.postalcode.$model" :placeholder="`${$root.$options.form.postalcode} *`"/>
          <div class="error" v-if="$v.postalcode.$dirty && !$v.postalcode.required">{{ $root.$options.form.error }}</div>
        </div>
        <div class="form-group" :class="{ 'form-group--error': $v.city.$error }">
          <input class="form__input" autocomplete="address-level2" @focus="focus" @blur="(e) => blur(e, 'city')" v-model.trim="$v.city.$model" :placeholder="`${$root.$options.form.city} *`"/>
          <div class="error" v-if="$v.city.$dirty && !$v.city.required">{{ $root.$options.form.error }}</div>
        </div>
        <div class="form-group" v-show="italy" :class="{ 'form-group--error': $v.state.$error }">
          <label class="form__label">{{ $root.$options.form.state }} <small>*</small></label>
          <div class="select__wrapper">
            <select class="form__input" @focus="focus" @blur="(e) => blur(e, 'state')" v-model.trim="$v.state.$model">
              <option v-for="(state, key) in states" :key="key" :value="key">{{ state }}</option>
            </select>
          </div>
          <div class="error" v-if="$v.state.$dirty && !$v.state.required">{{ $root.$options.form.error }}</div>
        </div>
        <div class="form-group" :class="{ 'form-group--error': $v.country.$error }">
          <label class="form__label">{{ $root.$options.form.country }} <small>*</small></label>
          <div class="select__wrapper">
            <select class="form__input" @focus="focus" @blur="(e) => blur(e, 'country')" v-model.trim="$v.country.$model">
              <option value="IT">Italia</option>
              <option value="estero">{{ $root.$options.form.countryForeign }}</option>
            </select>
          </div>
          <div class="error" v-if="$v.country.$dirty && !$v.country.required">{{ $root.$options.form.error }}</div>
        </div>
        <div class="form-group" v-show="foreign" :class="{ 'form-group--error': $v.otherCountry.$error }">
          <label class="form__label">{{ $root.$options.form.otherCountry }} <small>*</small></label>
          <div class="select__wrapper">
            <select class="form__input" @focus="focus" @blur="(e) => blur(e, 'otherCountry')" v-model.trim="$v.otherCountry.$model">
              <option v-for="(country, key) in countries" :key="key" :value="key">{{ country }}</option>
            </select>
          </div>
          <div class="error" v-if="$v.otherCountry.$dirty && !$v.otherCountry.required">{{ $root.$options.form.error }}</div>
        </div>
      
        <div id="smart-button-container" class="form-group form-group--double">
          <div>
            <div class="m-b-xxxs-xs">
              <label class="form__label">{{ $root.$options.form.product }} <small>*</small></label>
              <div class="select__wrapper">
                <select id="item-options" class="form__input" @blur="blur" @focus="focus">
                  <option value="Pesto alla Rucola" selected price="13">{{ $root.$options.labels.product_1 }}</option>
                  <option value="Pesto alla Rucola" price="26">{{ $root.$options.labels.product_2 }}</option>
                  <option value="Pesto alla Rucola" price="26">{{ $root.$options.labels.product_3 }}</option>
                </select>
              </div>
            </div>
            <div id="paypal-button-container" :class="[$v.$invalid ? 'paypal-disabled' : false]"></div>
          </div>
          <div class="t-center">
            <prismic-rich-text class="small" :field="$root.$options.labels.info_paypal" />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { required, minLength, email, requiredIf } from 'vuelidate/lib/validators'

import countries from '@/assets/data/country.json'
import states from '@/assets/data/state.json'

export default {
  data() {
    return {
      firstname: '',
      lastname: '',
      email: '',
      phone: '',
      cfiva: '',
      address: '',
      postalcode: '',
      city: '',
      state: 'AG',
      country: 'IT',
      otherCountry: '',
      countries,
      states,
      submitStatus: null
    }
  },
  props: {
    close: {
      type: Function,
      default: () => {}
    }
  },
  watch: {
    '$v.$invalid' (val) {
      if (!val) {
        this.$bus.$emit('formValid', true)
      } else {
        this.$bus.$emit('formValid', false)
      }
    }
  },
  validations: {
    firstname: {
      required,
      minLength: minLength(4)
    },
    lastname: {
      required,
      minLength: minLength(4)
    },
    email: {
      required,
      email,
      minLength: minLength(4)
    },
    phone: {
      required: requiredIf('foreign'),
    },
    cfiva: {
      required: requiredIf('italy'),
      minLength: minLength(4)
    },
    address: {
      required,
      minLength: minLength(4)
    },
    state: {
      required: requiredIf('italy'),
    },
    postalcode: {
      required,
    },
    city: {
      required,
      minLength: minLength(2)
    },
    country: {
      required,
    },
    otherCountry: {
      required: requiredIf('foreign'),
    },
  },
  computed: {
    foreign() {
      return this.country === 'estero';
    },
    italy() {
      return this.country === 'IT';
    }
  },
  mounted() {
  },
  methods: {
    blur(e, target) {
      e.target.classList.remove('active')
      if (target) {
        this.$v[target].$touch();
      }
    },
    focus(e) {
      e.target.classList.add('active')
    }
  }
}
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100vw;
  background: var(--color-alt);
  height: 100%;

  visibility: hidden;
  pointer-events: none;
  z-index: -1;
  opacity: 0;
  transition: 1s transform var(--ease), 0.5s opacity var(--ease);

  body.is-modal & {
    visibility: visible;
    pointer-events: auto;
    z-index: 20;
    opacity: 1;
    transform: translate3d(0%, 0%, 0);
    @include mq(sm) {
      transform: translate3d(-50%, -50%, 0);
    }
  }

  transform: translate3d(0%, 20px, 0);

  @include mq(sm) {
    border-radius: 33px;
  }
  -webkit-overflow-scrolling: auto;

  @include mq(sm) {
    max-width: 700px;
    width: 80vw;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0) translate3d(0%, 20px, 0);
    max-height: calc(var(--vh) * 80);
    height: auto;
  }
  overflow: scroll;
}

section {
  overflow: hidden;
  max-width: 100vw;
}

.close {
  position: absolute;
  right: 15px;
  top: 15px;
  width: 32px;
  height: 32px;
  cursor: pointer;
}

.form {
  padding-bottom: var(--spacer-sm);
  &-group {
    grid-column: 1 / -1;
    margin-top: var(--spacer-xxs);

    @include mq(sm) {
      grid-column: auto / span 6;
    }
  }
  &-group--double {
    grid-column: 1 / -1;
  }

  &__label {
    display: block;
    width: 100%;

    @extend %typo--xxs;
    text-transform: none;
    color: var(--color-primary);
    margin-bottom: calc(var(--spacer-xs) * 0.5);
  }
  &__input {
    display: block;
    width: 100%;
  }

  .error {
    margin-top: calc(var(--spacer-xs) * 0.5);
    @extend %typo--xs;
    opacity: 0.8;
  }
}
</style>

<style lang="scss">
#paypal-button-container {
  transition: 0.8s opacity var(--ease);
}
.paypal-disabled {
  opacity: 0.4;
  cursor: not-allowed !important;

  * {
    pointer-events: none;
    cursor: not-allowed !important;
  }
}

.select__wrapper {
  display: block;
  position: relative;

  &::after {
    position: absolute;
    right: 7px;
    top: 0;
    content: '';
    width: 20px;
    height: 20px;
    top: 50%;
    transform: translate3d(0, -50%, 0);
    z-index: 1;
    pointer-events: none;
    opacity: 0.7;
    background-size: 100% 100%;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 0 24 24' width='24'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M12 5.83L15.17 9l1.41-1.41L12 3 7.41 7.59 8.83 9 12 5.83zm0 12.34L8.83 15l-1.41 1.41L12 21l4.59-4.59L15.17 15 12 18.17z'/%3E%3C/svg%3E");

    @include mq(sm) {
      right: 14px;
    }
  }
  /* &:last-child {
    width: 20%;
    text-align: center;
  } */
}
select, input {
  display: block;
  position: relative;
  appearance: none;
  border: 0;
  height: 45px;
  width: 100%;
  padding: 0 15px;
  font-family: var(--font-base);
  font-size: 16px;
  background: var(--color-white);
  border-radius: 23px;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    transition: background-color 0s 50000s;
  }

  @include mq(sm) {
    height: 55px;
    padding: 0 20px;
  }
  border-bottom: 3px solid transparent;
  &.active {
    border-bottom-color: var(--color-primary);
  }
}

.small {
  p {
    font-size: 80%;
    line-height: 1.6em;

    a {
      color: var(--color-primary);
    }
  }
}
</style>