<template>
  <section>
    <div class="parallax">
      <img data-scroll data-scroll-speed="0.5" src="/static/img/leaves_rucola_08@2x.png" alt="">
    </div>
    <div class="grid g-12">
      <prismic-rich-text data-anim="title" data-delay="0.2" class="title color--primary" :field="data.primary.title"/>
      <div class="text">
        <prismic-rich-text data-anim="text" data-delay="0.6" class="m-t-xxxs-lg" :field="data.primary.text"/>
        <div class="imgs flex jc-sb m-t-xxxs-xxl ai-s">
          <img src="/static/svg/shape-white.svg" class="m-t-xxxs-xxl p-b-xxxs-xl" alt="" data-anim="fadeIn" data-delay="0.4">
          <img class="m-t-xxxs-xxl p-t-xxxs-xl" src="/static/svg/lines-horizontal-primary.svg" alt="" data-anim="fade" data-delay="0.6">
        </div>
      </div>
      <div class="first-img flex ai-c" data-anim="figure">
        <img src="/static/img/rucola@2x.png" data-delay="0.8" alt="">
        <img class="lines" data-scroll data-scroll-speed="2" src="/static/svg/lines-vertical-primary.svg" alt="">
      </div>
      <prismic-rich-text data-anim="title" data-delay="0.2" class="m-t-xxxs-xl p-t-sm-xl quote color--white" :field="data.primary.quote"/>
      <div class="last-img m-t-xxxs-xl" data-anim="figure" data-delay="0.2">
        <img src="/static/img/img_section_03b@2x.png" alt="">
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Project',
  props: {
    data: {
      type: Object,
      default: () => {}
    },
  }
}
</script>

<style lang="scss" scoped>
section {
  background: var(--color-alt);
  padding-bottom: 0;
}
.title {
  grid-column: 1 / -1;
  @include mq(sm) {
    grid-column: 2 / 8;
  }
}
.text {
  grid-column: 1 / -1;
  @include mq(sm) {
    grid-column: 2 / 8;
  }
}
.quote,
.last-img {
  grid-column: 1 / -1;

  @include mq(sm) {
    grid-column: 2 / 11;
  }
}
.last-img {
  @include mq($until: sm) {
    position: relative;
    left: calc(var(--spacer-xl) * -1);
    width: calc(var(--spacer-xl) * 2 + 100%);
  }
}
.first-img {
  position: relative;
  grid-column: 1 / -1;
  background: var(--color-accent);
  overflow: hidden;

  @include mq($until: sm) {
    margin-top: var(--spacer-md);
  }

  @include mq(sm) {
    right: calc(var(--spacer-xl) * -1);
    grid-column: 8 / -1;
  }

  img {
    position: relative;
    width: 100%;
  }

  .lines {
    position: absolute;
    left: 70%;
    top: 100%;
    max-width: 45px;

    @include mq($until: sm) {
      display: none;
    }
  }
}
.imgs {
  @include mq($until: sm) {
    display: none;
  }
}

.parallax {
  position: absolute;
  top: -2%;
  right: 0;
  pointer-events: none;
  z-index: 4;
  max-width: 16.166788588149%;

  @include mq($until: sm) {
    max-width: 40%;
  }
}
</style>