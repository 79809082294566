import gsap from 'gsap';
import fb from '@/assets/js/firebase';

export default function initPayPalButton(vm) {
  var shipping = 5;
  var itemOptions = document.querySelector("#smart-button-container #item-options");
  // var quantity = parseInt(10);
  // var quantitySelect = document.querySelector("#smart-button-container #quantitySelect");
  // if (!isNaN(quantity)) {
  //   quantitySelect.style.visibility = "visible";
  // }
  var quantity = 1;

  var selectedItemPrice = 0;
  var tax = 10;

  var priceTotal = 0;
  var itemTotalValue = 0;

  paypal.Buttons({
    locale: 'it_IT',
    style: {
      shape: 'pill',
      color: 'gold',
      layout: 'vertical',
      label: 'buynow',
    },
    createOrder: function(data, actions) {
      var selectedItemDescription = itemOptions.options[itemOptions.selectedIndex].textContent;
      selectedItemPrice = parseFloat(itemOptions.options[itemOptions.selectedIndex].getAttribute("price"));
      tax = (10 === 0) ? 0 : (selectedItemPrice * (parseFloat(10)/100));
      // if(quantitySelect.options.length > 0) {
      //   quantity = parseInt(quantitySelect.options[quantitySelect.selectedIndex].value);
      // } else {
      //   quantity = 1;
      // }

      shipping = vm.$v.country.$model !== 'IT' ? 12 : shipping;

      tax *= quantity;
      tax = Math.round(tax * 100) / 100;
      priceTotal = quantity * selectedItemPrice + parseFloat(shipping) + tax;
      priceTotal = Math.round(priceTotal * 100) / 100;
      itemTotalValue = Math.round((selectedItemPrice * quantity) * 100) / 100;

      let phone = null

      if (vm.$v.phone.$model !== '') {
        phone = {
          phone_number: {
            national_number: vm.$v.phone.$model,
          },
        };
      }

      return actions.order.create({
        // application_context: {
        //   shipping_preference: vm.$v.country.$model === 'IT' ? 'SET_PROVIDED_ADDRESS' : 'GET_FROM_FILE',
        // },
        payer: {
          name: {
            given_name: vm.$v.firstname.$model,
            surname: vm.$v.lastname.$model
          },
          email_address: vm.$v.email.$model,
          phone,
          country_code: vm.$v.country.$model === 'IT' ? vm.$v.country.$model : vm.$v.otherCountry.$model,
          address: {
            address_line_1: vm.$v.address.$model,
            admin_area_1: vm.$v.country.$model === 'IT' ? vm.$v.state.$model : null,
            admin_area_2: vm.$v.city.$model,
            postal_code: vm.$v.postalcode.$model,
            country_code: vm.$v.country.$model === 'IT' ? vm.$v.country.$model : vm.$v.otherCountry.$model,
          }
        },
        purchase_units: [{
          description: selectedItemDescription,
          shipping: {
            name: {
              full_name: `${vm.$v.firstname.$model} ${vm.$v.lastname.$model}`
            },
            address: {
              address_line_1: vm.$v.address.$model,
              admin_area_1: vm.$v.state.$model,
              admin_area_2: vm.$v.city.$model,
              postal_code: vm.$v.postalcode.$model,
              country_code: vm.$v.country.$model === 'IT' ? vm.$v.country.$model : vm.$v.otherCountry.$model,
            },
          },
          amount: {
            currency_code: 'EUR',
            value: priceTotal,
            breakdown: {
              item_total: {
                currency_code: 'EUR',
                value: itemTotalValue,
              },
              shipping: {
                currency_code: 'EUR',
                value: shipping,
              },
              tax_total: {
                currency_code: 'EUR',
                value: tax,
              }
            },
          },
        }]
      });
    },
    onShippingChange: function(data, actions) {
      var shippingAmount = 5.0;
      if (data.shipping_address.country_code != 'IT') {
        shippingAmount = 13.0;
      }

      return actions.order.patch([
      {
          op: 'replace',
          path: '/purchase_units/@reference_id==\'default\'/amount',
          value: {
            currency_code: 'EUR',
            value: Math.round((quantity * selectedItemPrice + parseFloat(shippingAmount) + tax) * 100) / 100,
            breakdown: {
              item_total: {
                currency_code: 'EUR',
                value: itemTotalValue
              },
              shipping: {
                currency_code: 'EUR',
                value: shippingAmount
              },
              tax_total: {
                currency_code: 'EUR',
                value: tax,
              }
            }
          }
      }]);
    },
    onApprove: function(data, actions) {
      gsap.to('.loader', {
        autoAlpha: 1,
        pointerEvents: 'auto',
        duration: 0.5,
        ease: 'expo.out',
      })

      return actions.order.capture().then(function(details) {
        fb.ref(`orders/${details.id}`).set({
          email: details.payer.email_address,
          cfiva: vm.$v.cfiva.$model,
          id: details.payer.payer_id,
          status: details.status,
          create_time: details.create_time,
          products: details.purchase_units[0].description,
          price: details.purchase_units[0].amount.value,
          name: details.purchase_units[0].shipping.name.full_name,
          address: details.purchase_units[0].shipping.address,
          sandbox: 'false',
        }).then(() => {
          const url = window.location.href.split('?')[0]
          const slash = url.slice(url.length - 1) === '/' ? '' : '/';
          window.location = `${url}${slash}thank-you?n=${details.payer.name.given_name}&s=${details.status}`;
        });
      });
    },
    onInit: function(data, actions) {
      actions.disable();

      vm.$bus.$on('formValid', (res) => {
        if (res) {
          actions.enable();
        } else {
          actions.disable();
        }
      })
    },
    onClick: function() {
      vm.$v.$touch();
    }
  }).render('#paypal-button-container');
}