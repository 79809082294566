import {
  init as initFade,
  anim as animFade
} from './fade'

import {
  init as initFadeIn,
  anim as animFadeIn
} from './fadeIn'

import {
  init as initTitle,
  anim as animTitle
} from './title'

import {
  init as initText,
  anim as animText
} from './text'

import {
  init as initList,
  anim as animList
} from './list'

import {
  init as initStagger,
  anim as animStagger
} from './stagger'

import {
  init as initFigure,
  anim as animFigure
} from './figure'

const init = new Map()

init.set('fade', initFade)
init.set('fadeIn', initFadeIn)
init.set('title', initTitle)
init.set('text', initText)
init.set('figure', initFigure)
init.set('list', initList)
init.set('slider', initFadeIn)
init.set('stagger', initStagger)

const anim = new Map()

anim.set('fade', animFade)
anim.set('fadeIn', animFadeIn)
anim.set('title', animTitle)
anim.set('text', animText)
anim.set('figure', animFigure)
anim.set('list', animList)
anim.set('slider', animFadeIn)
anim.set('stagger', animStagger)

const extra = new Map()

export {
  init,
  anim,
}
