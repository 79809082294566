<template>
  <section>
    <div class="imgs" data-anim="fadeIn" data-delay="0.2">
      <img data-mouse class="shape" src="/static/svg/shape-primary.svg" />
    </div>
    <prismic-rich-text class="title color--alt" data-anim="title" data-delay="0.2" :field="data.primary.title"/>

    <div class="slider m-t-xxxs-xxl" ref="slider" data-anim="slider" data-delay="0.4">
      <div
        v-for="item in data.items"
        :key="item.id"
        class="item"
      >
        <prismic-rich-text class="m-b-xxxs-xxl color--white em--color--alt t-center item__title" :field="item.title"/>
        <img :src="`/static/svg/${item.id}.svg`" :alt="item.title" />
        <prismic-rich-text class="m-t-xxxs-xxl item__text color--white t-center" :field="item.text"/>
      </div>
    </div>
  </section>
</template>

<script>
import 'flickity/dist/flickity.min.css'
import Flickity from 'flickity';

export default {
  name: 'Slider',
  props: {
    data: {
      type: Object,
      default: () => {}
    },
  },
  mounted() {
    this.slider = new Flickity(this.$refs.slider, {
      cellAlign: 'left',
      wrapAround: true,
      prevNextButtons: false,
      cellSelector: '.item',
      autoPlay: 3000,
    })
  }
}
</script>

<style lang="scss" scoped>
section {
  background: var(--color-alt);

  &::before {
    content: '';
    position: absolute;
    top: -2px;
    left: 0;
    width: 100%;
    height: 50%;
    background: var(--gradient-reverse);
    transform: translateZ(0px);
  }

  .title {
    position: relative;
    z-index: 1;
    
    text-align: left;

    @include mq(sm) {
      text-align: center;
      padding-top: calc(var(--vh) * 12.19512195122 + var(--spacer-lg));
    }
  }

  .imgs {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 2;

    @include mq($until: sm) {
      display: none;
    }


    & > * {
      position: absolute;
    }

    .shape {
      top: calc(var(--vh) * 2);
      left: 66.251830161054vw;
      width: 15.08345534407vw;
      height: calc(var(--vh) * 12.19512195122);
    }
  }

  .slider {
    padding-bottom: calc(16px + var(--spacer-md) * 2);
  }

  .item {
    background: var(--color-primary);
    padding: var(--spacer-sm);
    width: 90%;
    min-width: 300px;
    margin-right: var(--spacer-lg);
    min-height: 100%;

    @include mq(sm) {
      width: 25%;
      margin-right: var(--spacer-xl);
      padding: 4vw;
    }

    &__title {
      ::v-deep h4 {
        line-height: 1;
      }
      ::v-deep em {
        display: block;
      }
    }

    img {
      height: 120px;
      max-width: 100%;
      margin: 4vw auto;
    }
  }
}
</style>