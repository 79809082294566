import firebase from 'firebase/app';

import 'firebase/database';

const firebaseConfig = {
  apiKey: "AIzaSyCo-UfP4HPwCut5gjQnnNJ_fYWIgFa8BDY",
  authDomain: "rucolapiu-4b71c.firebaseapp.com",
  databaseURL: "https://rucolapiu-4b71c.firebaseio.com",
  projectId: "rucolapiu-4b71c",
  storageBucket: "rucolapiu-4b71c.appspot.com",
  messagingSenderId: "108585770162",
  appId: "1:108585770162:web:73263c3799a74089ea3dab"
};

firebase.initializeApp(firebaseConfig);
const fb = firebase.database();
export default fb;