<template>
  <section>
    <div class="parallax">
      <img src="/static/img/leavese_rucola_07@2x.png" data-scroll data-scroll-speed="1.5" alt="">
    </div>
    <div class="imgs">
      <img class="rhombus" src="/static/svg/rhombus-primary.svg" data-scroll data-scroll-speed="0.5" />
      <img class="pacman" src="/static/svg/pacman-alt.svg" data-mouse />
    </div>
    <div class="grid g-12 m-t-xxxs-md">
      <div class="video" data-anim="fadeIn" data-delay="0.2">
        <div :class="['video__wrapper', paused ? 'scale' : false]">
          <video poster="/static/img/cover.jpg" ref="video" playsinline loop>
            <source src="/static/video/video.mp4" type="video/mp4" />
          </video>
        </div>
        <div
          :class="['play', !paused ? 'hide' : false]"
          @click="togglePlay"
        >
          <img src="/static/svg/play.svg">
        </div>
      </div>

      <div class="divider m-b-xxxs-xl m-t-xxxs-lg">
        <img src="/static/svg/brand_divider.svg" alt="" data-anim="fadeIn" data-delay="0.2">
      </div>
      <div class="text__wrapper">
        <prismic-rich-text class="text color--primary" data-anim="text" data-delay="0.4" :field="data.primary.text"/>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Video',
  props: {
    data: {
      type: Object,
      default: () => {}
    },
  },
  data() {
    return {
      paused: true,
    };
  },
  methods: {
    togglePlay() {
      this.paused = !this.$refs.video.paused;
      if (this.$refs.video.paused) {
        this.$refs.video.play();
      } else {
        this.$refs.video.pause();
      }
    }
  }
}
</script>

<style lang="scss" scoped>
section {
  background: var(--gradient);

  .imgs {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;

    @include mq($until: sm) {
      display: none;
    }

    & > * {
      position: absolute;
    }

    .rhombus {
      top: 58.011049723757%;
      left: 14.641288433382%;
      width: 7.320644216691%;
    }

    .pacman {
      top: 28.360957642726%;
      right: 10.175695461201%;
      width: 12.884333821376%;
    }
  }

  .parallax {
    position: absolute;
    top: -2%;
    left: 0;
    pointer-events: none;
    z-index: 4;
    max-width: 16.166788588149%;

    @include mq($until: sm) {
      max-width: 40%;
    }
  }

  .video {
    position: relative;
    grid-column: 1 / -1;
    height: 0;
    padding-bottom: 150%;
    border-radius: 15px;

    @include mq(sm) {
      grid-column: 5 / 9;
    }
  }

  .play {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    cursor: pointer;
    z-index: 4;
    display: flex;
    align-items: center;
    justify-content: center;

    opacity: 1;
    transition: opacity 0.5s var(--ease);

    img {
      position: relative;
      z-index: 2;
    }

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: var(--color-primary);
      opacity: 0.5;
      pointer-events: none;
      border-radius: 15px;
    }
    &.hide {
      opacity: 0;
    }
  }

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 15px;
  }

  .video__wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: transform 1.2s var(--ease);

    &.scale {
      transform: scale(0.9);
    }
  }

  .divider {
    grid-column: 1 / -1;
    @include mq(sm) {
      grid-column: 5 / 9;
    }

    img {
      max-width: 80%;
      margin: 0 auto;
    }
  }

  .text__wrapper {
    grid-column: 1 / -1;
    @include mq(sm) {
      grid-column: 3 / 11;
    }
  }

  .text {
    margin: 0 auto;
    max-width: 620px;

    ::v-deep p {
      @extend %typo--sm-2;
    }
  }
}
</style>