import Vue from 'vue';
import { common, vueRouter, nuxt } from '@prismicio/vue/components';

Vue.prototype.$prismic = {

  linkResolver() { /* ... */}
}

Object.entries(common).forEach(([_, component]) => {
    Vue.component(component.name, component)
})