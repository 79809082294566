import gsap from 'gsap'

const init = (el) => {
  gsap.set(el.children, {
    scale: 1.15,
    transformOrigin: '50% 100%',
  });

  const params = {
    overflow: 'hidden',
    opacity: 0,
    y: 40,
  }

  return gsap.set(el, Object.assign({}, params))
}

const anim = (el, extraParams) => {
  const tl = gsap.timeline(extraParams)

  tl.to(el, 1.2, {
    opacity: 1,
    y: 0,
    ease: 'expo.out',
  }, 0);

  tl.to(el.children, 1.4, {
    scale: 1,
    ease: 'expo.out',
    clearProps: 'all',
    onComplete: () => {
      el.children[0].classList.add('animated');
    }
  }, 0);

  return tl
}

export {
  init,
  anim
}
