<template>
  <section>
    <div class="parallax">
      <div data-mouse>
        <img class="leaf" src="/static/img/leavese_rucola_02@2x.png" data-scroll data-scroll-speed="-0.5" alt="">
        <div class="circle"></div>
      </div>
    </div>
    <div class="grid g-12">
      <prismic-rich-text data-anim="title" data-delay="0.2" class="title color--primary a--color--accent m-b-xxxs-xl p-b-xs-md" :field="data.primary.title"/>
      <article
        v-for="(article, index) in articles"
        :key="article.guid"
        class="a--color--purple"
      >
        <a :href="article.link" target="_blank" class="default">
          <figure>
            <div class="figure" data-anim="figure" :data-delay="0.2 * index">
              <img :src="article.picture" alt="">
            </div>
            <figcaption class="m-t-xxxs-xs p-t-xs-xs" data-anim="text" :data-delay="0.2 * index">
              <h5>{{ article.title }}</h5>
            </figcaption>
          </figure>
        </a>
      </article>
    </div>
  </section>
</template>

<script>
import { init } from '@/assets/js/animations'

import scroll from '@/assets/js/scroll';

export default {
  name: 'Feed',
  props: {
    data: {
      type: Object,
      default: () => {}
    },
  },
  data() {
    return {
      articles: []
    }
  },
  mounted() {
    this.fetchMedium('rucolapiu');
  },
  watch: {
    articles(val) {
      if (val) {
        this.$nextTick(() => {
          const newDom = Array.from(document.querySelectorAll('article [data-anim]'));
          newDom.map(el => init.get(el.dataset.anim)(el));
          this.$parent.medusa.pushToTarget('snakesss', newDom);
          scroll.ref.update()
        });
      }
    }
  },
  methods: {
    fetchMedium(username) {
      const url = `https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@${username}`;
      fetch(url)
      .then(response => response.json())
      .then(data => {
        data.items.forEach((el, index) => {
          if (index < 3) {
            const item = {
              title: el.title,
              link: el.link,
              guid: el.guid,
              picture: el.thumbnail
            }
            this.articles.push(item);
          }
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>

.parallax {
  position: absolute;
  top: 2%;
  right: 2%;
  pointer-events: none;
  z-index: 4;
  max-width: 20%;

  @include mq($until: sm) {
    display: none;
  }

  @include mq(sm) {
    top: -18%
  }

  img {
    position: relative;
    z-index: 1;

    &.leaf {
      z-index: 30;
      pointer-events: none;
    }
  }

  .circle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-45%, -40%, 0) scale(0.62);
    width: 100%;
    height: 0;
    background: var(--color-primary);
    border-radius: 50%;
    padding-bottom: 100%;
  }
}

section {
  background: var(--gradient);
}

.grid {
  padding-bottom: var(--spacer-md);
  
  @include mq(sm) {
    padding-top: var(--spacer-xl);
    padding-bottom: var(--spacer-xl);
  }
}

article {
  grid-column: 1 / -1;
  @include mq($until: sm) {
    & + article {
      margin-top: var(--spacer-xl);
    }
  }

  @include mq(sm) {
    padding: var(--spacer-md);
    grid-column: auto / span 4;
  }
}

.default {
  img.animated {
    transition: 1s transform var(--ease);
  }
  &:hover {
    img.animated {
      transform: scale(1.1);
    }

    figcaption {
      &::before {
        transform: scaleX(0.5);
      }
    }
  }
}

.figure {
  position: relative;
  width: 100%;
  padding-bottom: 70%;
  
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

figcaption {
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 33.33%;
    height: 5px;
    background: var(--color-alt);
    transition: 1s transform var(--ease);
    transform-origin: 0% 50%;

    @include mq($until: sm) {
      top: -8px;
      height: 4px;
    }
  }
}

.title {
  grid-column: 1 / -1;
  text-align: left;

  @include mq(sm) {
    text-align: center;
    grid-column: 4 / 10;
  }
}
</style>