<template>
  <section class="flex ai-c">
    <header class="static">
      <div class="wrapper">
        <div class="flex ai-c">
          <div class="logo" data-anim="fadeIn">
            <a :href="homeUrl" class="default">
              <img :src="require('@/assets/svg/logo.svg')" />
            </a>
          </div>
        </div>
      </div>
    </header>
    <div class="imgs" data-anim="stagger" data-delay="1">
      <div class="square"><div data-mouse><img src="/static/svg/square-primary.svg" /></div></div>
      <div class="circle">
        <div class="circle__inner">
          <div data-scroll data-scroll-speed="1.5">
            <img src="/static/svg/ellipse-accent.svg" />
            <img class="hand" src="/static/img/Unione 2@2x.png" />
          </div>
        </div>
      </div>
      <div class="ellipse">
        <div>
          <img data-mouse src="/static/svg/ellipse-primary.svg" />
          <div class="leaves">
            <img data-scroll data-scroll-speed="-0.2" src="/static/img/rucola_leaves_012@3x.png" />
          </div>
        </div>
      </div>
      <div class="rhombus"><div><img src="/static/svg/rhombus-alt.svg" data-scroll data-scroll-speed="1" /></div></div>
      <div class="lines"><div data-scroll data-scroll-speed="-1"><img src="/static/svg/lines-horizontal-alt.svg" /></div></div>
    </div>
    <div class="grid g-12">
      <div data-anim="title" data-delay="0.2" class="title color--primary">
        <h1>{{ title }}</h1>
      </div>
      <prismic-rich-text data-anim="text" data-delay="0.6" class="text m-t-xxxs-lg" :field="data.text"/>
    </div>
  </section>
</template>

<script>
import queryString from 'query-string';

export default {
  name: 'Hero',
  props: {
    data: {
      type: Object,
      default: () => {}
    },
  },
  data() {
    return {
      name: queryString.parse(location.search).n,
    }
  },
  computed: {
    title() {
      return this.data.title[0].text.replace('{name}', this.name);
    },
    homeUrl() {
      const removeQs = window.location.href.split('?')[0];
      const removePath = removeQs.replace('/thank-you', '');
      return removePath;
    }
  }
}
</script>

<style lang="scss" scoped>
header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 12;
  justify-content: center;

  .wrapper > .flex {
    min-height: 48px;
    @include mq(sm) {
      min-height: 72px;
    }
  }

  .logo {
    flex: 1 0 0;
    img {
      max-width: 186px;
      margin: 0 auto;

      @include mq(sm) {
        max-width: 330px;
      }
    }
  }
}
section {
  position: relative;
  z-index: 2;
  min-height: calc(var(--vh) * 100);
  background: var(--gradient);
  overflow: hidden;

  @include mq($until: sm) {
    padding-top: 96px;
    align-items: flex-start;
  }

  ::v-deep a {
    color: var(--color-primary);
  }
}
.title {
  grid-column: 1 / -1;
  @include mq(sm) {
    grid-column: 5 / 11;
  }
}
.text {
  grid-column: 1 / -1;
  @include mq(sm) {
    grid-column: 6 / 11;
  }

  @include mq($until: sm) {
    margin-top: 300px;
  }
}

.imgs {
  position: absolute;
  top: 0;
  left: 50%;
  width: 100%;
  height: 100%;
  pointer-events: none;
  max-width: 1920px;
  transform: translate3d(-50%, 0, 0);

  & > * {
    position: absolute;

    & > div {
      position: relative;
      width: 100%;
      padding-bottom: 100%;
      height: 0;
    }

    img {
      width: 100%;
    }
  }

  .square {
    top: 37.80487804878%;
    left: 5.4172767203514%;
    width: 10.248901903367%;
    height: 0;

    @include mq($until: sm) {
      display: none;
    }
  }
  .ellipse {
    top: 67.317073170732%;
    left: 30.453879941435%;
    width: 10.248901903367%;
    height: 0;

    .leaves {
      position: absolute;
      top: 100%;
      left: 0%;
      transform: translate3d(-50%, -50%, 0);
      min-width: 120%;
    }

    @include mq($until: sm) {
      display: none;
    }
  }
  .rhombus {
    bottom: 0;
    left: 50.292825768668%;
    width: 7.3206442166911%;
    height: 0;

    @include mq($until: sm) {
      display: none;
    }

    div {
      transform: translate3d(0, -100%, 0);
    }
  }
  /* .leaves {
    top: 79.268292682927%;
    left: 18.814055636896%;
    width: 16.178623718887%;
    height: 22.80487804878%;
  } */
  .lines {
    top: 45%;
    left: 0;
    width: 118px;
    height: 40px;

    @include mq(sm) {
      top: 76.829268292683%;
      right: 0;
      left: auto;
      width: 8.6383601756955%;
      height: 4.8780487804878%;
    }
  }
  .circle {
    top: 43.292682926829%;
    left: 10.761346998536%;
    width: 23.133235724744%;
    height: 0;
    z-index: 21;
    pointer-events: none;

    @include mq($until: sm) {
      left: auto;
      right: -10%;
      top: 40%;
      width: 50%;

      .circle__inner {
        transform: scaleX(-1);
      }
    }


    .hand {
      position: absolute;
      bottom: 0;
      width: 100%;
    }
  }
}

</style>