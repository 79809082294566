import gsap from 'gsap'

const init = (el) => {
  const params = {
    autoAlpha: 0,
    y: 60,
  }

  return gsap.set(el.children, Object.assign({}, params))
}

const anim = (el, extraParams) => {
  const params = {
    autoAlpha: 1,
    y: 0,
    clearProps: 'transform',
    ease: 'expo.out',
    duration: 0.8,
    clearProps: 'all',
    stagger: {
      amount: 0.8,
    }
  }
  return gsap.to(el.children, Object.assign({}, params, extraParams))
}

export {
  init,
  anim
}
