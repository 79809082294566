export const findPicture = (nodeValue) => {
  const elem = document.createElement('div');
  elem.style.display = 'none';
  document.body.appendChild(elem);
  elem.innerHTML = nodeValue;
  const src = elem.querySelector('img').src;
  elem.remove();
	return src;
};

export const isTouchDevice = () => {
  const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
  const mq = query => window.matchMedia(query).matches;
  /* eslint-disable-next-line no-mixed-operators, no-undef */
  if (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) {
    return true;
  }
  const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
  return mq(query);
};