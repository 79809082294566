<template>
  <div id="app">
    <template v-if="isThxPage">
      <ThxPage :data="post.thx" />
    </template>
    <template v-else>
      <AppHeader :open="toggleModal" />
      <main
        data-scroll-container
        ref="main"
      >
        <BlockHero :data="post.hero" />
        <BlockSlider :data="post.intro" />
        <BlockProject :data="post.progetto" />
        <BlockVideo :data="post.video" />
        <BlockProduct :data="post.prodotto" :open="toggleModal" />
        <BlockFeed :data="post.feed" />
        <AppFooter :data="post.footer" />
      </main>
      <ModalForm :close="toggleModal" ref="paypal" />
    </template>
  </div>
</template>

<script>
import imagesLoaded from 'imagesloaded'
import debounce from 'lodash.debounce'
import gsap from 'gsap'
import PrismicVue from '@prismicio/vue'
import Medusa from '@adoratorio/medusa'

import eventBus from '@/assets/js/eventBus'
import isMobile from '@/assets/js/isMobile'
import scroll from '@/assets/js/scroll'
import { isTouchDevice } from '@/assets/js'
import initPayPalButton from '@/assets/js/paypal';

import { init, anim } from '@/assets/js/animations'

import AppHeader from '@/components/ui/AppHeader';
import AppFooter from '@/components/ui/AppFooter';
import ThxPage from '@/components/ThxPage';
import BlockHero from '@/components/BlockHero';
import BlockSlider from '@/components/BlockSlider';
import BlockProject from '@/components/BlockProject';
import BlockVideo from '@/components/BlockVideo';
import BlockProduct from '@/components/BlockProduct';
import BlockFeed from '@/components/BlockFeed';
import ModalForm from '@/components/ModalForm';

export default {
  name: 'App',
  components: {
    AppHeader,
    AppFooter,
    BlockHero,
    BlockSlider,
    BlockProject,
    BlockVideo,
    BlockProduct,
    BlockFeed,
    ThxPage,
    ModalForm
  },
  data() {
    return {
      lang: 'it',
      post: window._DATA,
      animEls: [],
      medusa: null,
      isThxPage: window.location.pathname.split('/').includes('thank-you'),
      mouseEls: [],
      isMoving: false,
      movingTimeout: null,
      isModal: false,
    }
  },
  mounted() {
    if (!this.isThxPage) {
      scroll.init()

      imagesLoaded(this.$refs.main, () => {
        scroll.ref.update()
      })

      initPayPalButton(this.$refs.paypal);
    }

    this.animEls = Array.from(document.querySelectorAll('[data-anim]'));
    this.animEls.map(el => init.get(el.dataset.anim)(el));

    this.medusa = new Medusa({
      targets: [
        {
          id: 'snakesss',
          container: this.$el,
          nodes: this.animEls,
          threshold: 0.3,
          callback: entry => anim.get(entry.target.dataset.anim)(entry.target, { delay: entry.target.dataset.delay }),
          mode: 'once'
        }
      ]
    })

    this.setVh()

    window.addEventListener('resize', debounce(() => {
      this.$bus.$emit('windowResized')
    }))

    this.$bus.$on('windowResized', this.resize)

    this.mouseEls = Array.from(this.$el.querySelectorAll('[data-mouse]'));
    this.mouseEls.map(el => { this.mouseFx(el) })
  },
  methods: {
    resize () {
      this.setVh()
      if (scroll.ref) scroll.ref.update()
    },
    setVh () {
      const vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    },
    mouseFx(el) {
      el.addEventListener('mousemove', this.moveFx)
    },
    moveFx(e) {
      if (isTouchDevice()) return;
      const { width, height } = e.target.getBoundingClientRect();
      if (this.movingTimeout) clearTimeout(this.movingTimeout);
      gsap.killTweensOf(e.target);
      gsap.to(e.target, {
        xPercent: Math.min(Math.abs(e.movementX * 100), width) * e.movementX > 0 ? -5 : 5,
        yPercent: Math.min(Math.abs(e.movementY * 100), height)  * e.movementY > 0 ? -5 : 5,
        ease: 'power3.out',
        duration: 1,
      })

      this.movingTimeout = setTimeout(() => {
        gsap.killTweensOf(e.target);
        gsap.to(e.target, {
          xPercent: 0,
          yPercent: 0,
          ease: 'power3.out',
          overwrite: 'all',
          duration: 1,
        })
      }, 500);
    },
    toggleModal() {
      document.body.classList.toggle('is-modal')
      this.isModal = !this.isModal;
      if (this.isModal) {
        this.$refs.paypal.$el.scroll(0, 0)
        scroll.ref.stop();
      } else {
        scroll.ref.start();
      }
    },
    // enterModal(el, done) {
    //   gsap.set(el, {
    //     autoAlpha: 0,
    //     y: 20,
    //   })
    //   gsap.to(el, {
    //     autoAlpha: 1,
    //     y: 0,
    //     ease: 'expo.out',
    //     duration: 1,
    //   }).then(done)
    // },
    // leaveModal(el, done) {
    //   gsap.to(el, {
    //     autoAlpha: 0,
    //     y: 20,
    //     ease: 'expo.out',
    //     duration: 0.8,
    //   }).then(done)
    // }
  }
};
</script>

<style lang="scss">
@import '@/assets/scss/core/_base.scss';
@import '@/assets/scss/core/_utils.scss';
@import '@/assets/scss/core/_flex.scss';
@import '@/assets/scss/core/_grid.scss';
@import '@/assets/scss/core/_spacer.scss';

main {
  overflow: hidden;
}

body {
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .5);
    opacity: 0;
    pointer-events: none;
    transition: 0.8s opacity var(--ease);
  }
  &.is-modal {
    height: calc(var(--vh) * 100);
    overflow: hidden;

    &::after {
      pointer-events: auto;
      opacity: 1;
      z-index: 19;
    }
  }
}

section {
  position: relative;
  padding: var(--spacer-md);

  @include mq(sm) {
    padding: var(--spacer-xl);
  }
}

a:not(.default) {
  display: inline-block;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: currentColor;
    transform: scaleX(0);
    transition: transform 1s var(--ease);
    transform-origin: 0% 50%;
  }

  &:hover {
    &::after {
      transform: scaleX(1);
    }
  }
}

h1 {
  position: relative;
  @extend %typo--xxl;

  @include mq(sm) {
    &::before {
      content: '';
      width: 10px;
      height: 95%;
      position: absolute;
      top: 2.5%;
      left: calc(var(--spacer-md) * -1);
      background: currentColor;
    }
  }

}

h2 {
  @extend %typo--xl;
}

h3 {
  @extend %typo--lg;
}

h4 {
  @extend %typo--md;
  em {
    font-style: normal;
  }
}

h5 {
  @extend %typo--md-2;
}

.text {
  max-width: 530px;
}

p {
  @extend %typo--sm;
}

.flickity-viewport {
  overflow: visible;
}
.flickity-page-dots {
  bottom: -16px;
  padding: var(--spacer-lg) 0;

  .dot {
    border-radius: 0;
    transform: rotate(-45deg);
    background: var(--color-white);
    opacity: 1;

    &.is-selected {
      background: var(--color-accent);
    }
  }
}

[data-scroll-container] {
  will-change: transform;
}

[data-anim] {
  will-change: transform, opacity;
}
[data-mouse] {
  pointer-events: auto;
  z-index: 20;
  will-change: transform;

  &::after {
    content: '';
    left: -50%;
    top: -50%;
    width: 200%;
    height: 200%;
    position: absolute;
  }

  * {
    pointer-events: none;
  }
}
</style>
