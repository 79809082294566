import gsap from 'gsap'

const init = (el, extraParams) => {
  const params = {
    autoAlpha: 0,
  }
  return gsap.set(el, Object.assign({}, params))
}

const anim = (el, extraParams) => {
  const params = {
    autoAlpha: 1,
    duration: 1,
    ease: 'expo.inOut',
    clearProps: 'all',
  }
  return gsap.to(el, Object.assign({}, params, extraParams))
}

export {
  init,
  anim
}
